body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @include themed() {
    background-color: t('background-color-alt');
    color: t('foreground-color');
  }
}

html {
  overflow-y: scroll;
}

body, html {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: 0;
}

p {
  margin: 0.5em 0;
}

figure {
  width: 100%;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: $color-blue;
  background-color: inherit;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

video {
  width: 100%;
  border-radius: 0.3em;
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
  margin-top: 0.4em;
  text-align: left;
}

h1 {
  font-size: 1.6em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.3em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1.1em;
}

/**
 * Links
 */
a {
  color: $color-pink;
  background-color: inherit;
  text-decoration: underline;
  text-underline-offset: 5px;
  word-wrap: break-word;

  &:hover {
    @include themed() {
      color: t('foreground-color');
      background-color: inherit;
    }
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}


/**
 * Blockquotes
 */
blockquote {
  @include themed() {
    color: t('background-color');
    background-color: inherit;
  }
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}


/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border-radius: 0.3em;
  @include themed() {
    background-color: $color-background-lighter;
    color: $color-foreground-dark;
  }
  font-family: $syntax-font-family;
}

code {
  padding: 1px 5px;
  text-transform: initial;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

/**
 * Wrapper
 */
.wrapper {
  max-width: calc(#{$content-width} - (#{$spacing-unit}));
  margin: 0 15px;
  padding-right: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
  @extend %clearfix;
}

.page-content .wrapper {
  border: 1px solid $color-foreground;
  margin-bottom: 15px;
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

a:has(> code) {
  text-decoration: none;
}

.post-link {
  text-decoration: none;
}

.recent-posts-title {
  margin-bottom: 0.5em;
}

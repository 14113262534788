$color-background-dark: #1E2227;
$color-background-darker: #15171A;
$color-foreground: #FFFFFF;
$color-foreground-dark: lighten($color-background-dark, 30%);

$color-background-light: #1A237E;
$color-background-lighter: #283371;
$color-foreground-dark: #FFFFFF;
$color-foreground-darker: #E8EAF6;

$color-pink: #FF80AB;
$color-red: #FF5252;
$color-orange: #FFAB40;
$color-yellow: #FFEB3B;
$color-teal: #1DE9B6;
$color-green: #00FF9C;
$color-cyan: #18FFFF;
$color-blue: #E7F3FE;
$color-indigo: #536DFE;
$color-purple: #B388FF;

.color-pink {
  color: $color-pink !important;
  background-color: inherit;
}

.color-red {
  color: $color-red !important;
  background-color: inherit;
}

.color-orange {
  color: $color-orange !important;
  background-color: inherit;
}

.color-yellow {
  color: $color-yellow !important;
  background-color: inherit;
}

.color-teal {
  color: $color-teal !important;
  background-color: inherit;
}

.color-green {
  color: $color-green !important;
  background-color: inherit;
}

.color-cyan {
  color: $color-cyan !important;
  background-color: inherit;
}

.color-blue {
  color: $color-blue !important;
  background-color: inherit;
}

.color-indigo {
  color: $color-indigo !important;
  background-color: inherit;
}

.color-purple {
  color: $color-purple !important;
  background-color: inherit;
}

.color-pink-hover:hover {
  color: $color-pink !important;
  background-color: inherit;
}

.color-red-hover:hover {
  color: $color-red !important;
  background-color: inherit;
}

.color-orange-hover:hover {
  color: $color-orange !important;
  background-color: inherit;
}

.color-yellow-hover:hover {
  color: $color-yellow !important;
  background-color: inherit;
}

.color-teal-hover:hover {
  color: $color-teal !important;
  background-color: inherit;
}

.color-green-hover:hover {
  color: $color-green !important;
  background-color: inherit;
}

.color-cyan-hover:hover {
  color: $color-cyan !important;
  background-color: inherit;
}

.color-blue-hover:hover {
  color: $color-blue !important;
  background-color: inherit;
}

.color-indigo-hover:hover {
  color: $color-indigo !important;
  background-color: inherit;
}

.color-purple-hover:hover {
  color: $color-purple !important;
  background-color: inherit;
}
.site-header {
  min-height: $spacing-unit * 1.865;
  line-height: $base-line-height * $base-font-size * 2.25;

  // Positioning context for the mobile navigation icon
  position: relative;

  .blinking_cursor {
    animation: blinker 1s none infinite;
    font-size: $base-font-size * 1.25;
  }

  .command_prompt {
    color: $color-blue;
    background-color: inherit;
    font-size: $base-font-size;
    margin-left: 0.1em;

    &:before {
      content: $icon-chevron-right;
      @include font-awesome-icon;
      margin-right: 0.2em;
    }
  }
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  margin-bottom: 0;
  float: left;
  font-family: $base-font-family;
  width: 100%;
  text-align: center;

  &,
  &:visited,
  &:hover {
    @include themed() {
      color: t('foreground-color');
      background-color: inherit;
    }
    text-decoration: none;
  }
}

.site-footer {
  margin-bottom: 15px;
  text-align: center;

  & p {
    margin: 0;
  }
}

.license-icons {
  text-decoration: none;
}

.wrapper {
  text-align: justify;
}

/**
 * Author box
 */

.author-box {
  margin-bottom: 1em;
  text-align: left;
  min-height: 72px;
  font-style: italic;

  & > .author-avatar {
    float: left;
    white-space: pre-line;
    margin-right: 1em;
    width: 72px;
    height: 72px;
    border-radius: 0.3em;
  }
}

.post-title {
  text-align: left;
  margin-bottom: 0;
}

.post-description {
  margin-top: 1em;
  font-size: $base-font-size * 1.15;
  @include themed() {
    color: t('foreground-color');
    background-color: inherit;
  }
  overflow: hidden;
  width: 100%;
  margin-bottom: 1em;
  margin-left: 0.5em;

  &:after {
    content: "_";
    animation: blinker 1s none infinite;
    font-size: 16px;
  }

  &:before {
    content: $icon-chevron-right;
    @include font-awesome-icon;
    margin-right: 0.5em;
    color: $color-blue;
    background-color: inherit;
  }
}

.post {
  margin-bottom: 15px;

  .post-date {
    margin-top: 1em;
    margin-bottom: 1em;
    @include themed() {
      color: t('foreground-color-alt');
      background-color: inherit;
    }
    font-size: 12px;
  }

  strong {
    @include themed() {
      color: t('foreground-color-contrast');
      background-color: inherit;
    }
  }

  img {
    border-radius: 0.3em;
    width: 100%;
  }

  ol > li {
    margin-left: 1em;
    text-align: left;
    margin-bottom: 0.6em;
  }

  ul > li {
    text-align: left;
    margin-bottom: 0.6em;
    @include themed() {
      color: t('list-primary');
      background-color: inherit;
    }
    list-style: none;
    margin-left: 0;

    &:before {
      content: $icon-check;
      @include font-awesome-icon;
      margin-right: 0.5em;
    }

    & > p {
      display: inline;
    }
  }

  ul, ol {
    padding: 0;
    margin-left: 0.5em;
  }


  & > blockquote {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0;
    font-style: italic;
    @include themed() {
      color: t('quote-primary');
      background-color: inherit;
    }

    & > p {
      display: block;
      padding-left: 48px;
    }

    &:before {
      content: $icon-quote-right;
      @include font-awesome-icon;
      margin-right: -2em;
      float: left;

      font-size: 2em;
      transform: translateY(-10px);
    }

    li {
      @include themed() {
        color: t('quote-secondary');
        background-color: inherit;
      }

      &:before {
        content: $icon-minus;
      }
    }
  }
}

.post-link-wrapper {
  margin-bottom: 1.1em;

  & > .post-link {
    font-size: $post-link-font-size;
    display: inline-block;
    text-align: initial;
  }

  & > .post-meta {
    @include themed() {
      color: t('foreground-color-alt');
      background-color: inherit;
    }
  }
}

.tag-cloud {
  margin-bottom: 15px;
  text-align: left;

  & > a {
    display: inline-block;
    margin: 0.3em 0.3em 0.3em 0;
  }
}

.tag, .tag-cloud > a {
  color: $color-green;
  background-color: inherit;
  @include themed() {
    background-color: darken(t('background-color'), 5%);
    color: inherit;
  }
  padding: 0.3em 0.6em;
  border-radius: 0.3em;
  text-decoration: none;

  &:hover {
    @include themed() {
      background-color: darken(t('background-color'), 10%);
      color: inherit;
    }
  }
}

.tag {
  display: inline-block;
  margin: 0.3em 0.3em 0.3em 0;
}

.post-tags {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  text-align: initial;
}

.credits {
  font-size: 10px;
  @include themed() {
    color: lighten(t('foreground-color-alt'), 5%);
    background-color: inherit;
  }

  & > a {
    @include themed() {
      color: lighten(t('foreground-color-alt'), 10%);
      background-color: inherit;
    }
    text-decoration: underline;
  }
}

.pagination {
  font-size: $post-link-font-size;
  margin: 1em 0;
  text-align: center;

  & > a, & > span {
    font-weight: normal;
    display: inline-block;
    min-width: 1em;

    padding: 0.3em 0.6em;
    border-radius: 0.3em;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }

  & > a {
    color: $color-green;
    background-color: inherit;
    @include themed() {
      background-color: t('background-color');
      color: inherit;
    }

    &:hover {
      @include themed() {
        background-color: lighten(t('background-color'), 5%);
        color: inherit;
      }
    }
  }

  & > span {
    @include themed() {
      color: darken(t('foreground-color'), 20%);
      background-color: darken(t('background-color'), 3%);
    }
  }

  i {
    font-style: normal;
  }
}

.social_links {
  float: right;
  text-align: right;
  display: inline-block;

  & > a {
    @include themed() {
      color: darken(t('foreground-color'), 10%);
      background-color: inherit;
    }
    display: inline-block;
    padding-left: 0.3em;
    font-size: $post-link-font-size * 1.35;
  }
}

ul.related-posts {

  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 1em;
  padding: 0;

  & > li {
    list-style: none;
    margin-left: 0;

    a {
      display: inline-block;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      font-size: 1.3em;
      text-align: initial;
      text-decoration: none;
    }
  }
}

#theme-toggle {
  background-color: transparent;
  color: inherit;
  border: none;
  float: right;
  display: inline-block;

  &.dark {
    &:before {
      content: $icon-flask;
      @include font-awesome-icon;
    }

    @include themed() {
      color: t('foreground-color');
      background-color: inherit;
    }
  }

  &.light {
    &:before {
      content: $icon-flask;
      @include font-awesome-icon;
    }

    color: $color-yellow;
    background-color: inherit;
  }
}

.recommended-links {
  position: fixed;
  top: 15px;
  right: 15px;
  text-align: right;
  padding: 15px;
  border: 1px solid $color-foreground;
  max-width: 300px;
  
  @media screen and (max-width: ($content-width + 300px)) {
    display: none;
  }

  h3 {
    @include themed() {
      color: t('foreground-color');
      background-color: inherit;
    }
    margin-bottom: 15px;
    margin-top: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 0.5em;
    line-height: 1.4;

    &:before {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    @include themed() {
      color: t('foreground-color-alt');
      background-color: inherit;
    }

    &:hover {
      @include themed() {
        color: $color-blue;
        background-color: inherit;
      }
    }
  }
}
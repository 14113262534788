@keyframes blinker {
  50% {
    opacity: 0;
  }
}


@keyframes type {
  from {
    width: 0;
  }
}
